import { FormControl, } from "../../../../components";

import * as APIController from "../../../../API/APIController";
import { clsGetCategoryForDropdown, clsAddEditCalendarAsset, clsGetCalendarAssetByID } from '../../../../API/APIParameters'
import { useEffect, useState } from "react";
import { DocumentTypes, GetDateWithCommonFormat, monthNames } from "../../../../config/appConstant";

export default function ScheduleAutomationcalenderForm(props) {
	const FormFields =
		[
			{ Type: 'dropdown', Label: 'Select Category', Name: 'CategoryID', DefaultValue: '0', IsMandatory: '1', SequenceNo: '3', },
			{ Type: 'date', Label: 'Select Date', Name: 'AssetDate', DefaultValue: '0', IsMandatory: '1', SequenceNo: '4', IsFullWidth: true, CalendarMonth: props.CalendarMonth, CalendarYear: props.CalendarYear, IsSingleMonth: true },
			{ Type: 'time', Label: 'Time', Name: 'AssetDateTime', DefaultValue: '11:00', IsMandatory: '1', SequenceNo: '4', IsFullWidth: true },
			{ Type: 'input', Label: 'Veeva Code', Name: 'VeevaCode', DefaultValue: '0', IsMandatory: '1', SequenceNo: '4', },
			{ Type: 'input', Label: 'Asset Name', Name: 'Name', DefaultValue: '0', IsMandatory: '1', SequenceNo: '4', },
			{ Type: 'HtmlEditer', },
			{ Type: 'uploadAssets', Label: 'Select Asset', Name: 'Upload', isMultipleAdded: false, DocumentTypeID: DocumentTypes.CalendarAsset, IsFullWidth: true, ObjectID: '', IsMandatory: 1, IsEdit: '', IsSavedClick: 0 },
			{ Type: 'uploadAssets', Label: 'Select Thumbnail Image', Name: 'Upload', isMultipleAdded: false, DocumentTypeID: DocumentTypes.CalendarAssetPreview, IsFullWidth: true, ObjectID: '', IsMandatory: 1, IsEdit: '', IsSavedClick: 0 },
			{ Type: 'switch', Label: 'Is Lead Gen Post?', Name: 'IsLeadGenPost', DefaultValue: '1', IsMandatory: '0', SequenceNo: '9', },
			{ Type: 'button', Button: [{ lable: 'Save', Buttontype: 2 }, { lable: 'Close', Buttontype: 1 }] }
		]
	const [FormDataObject, setFormDataObject] = useState(FormFields)
	const [formEdit, setFormEdit] = useState(false)
	const [FormEditData, setFormEditData] = useState('');
	const [EditID, setEditID] = useState(props.EditID)
	const [CalendarID, setCalendarID] = useState(props.CalendarID)
	const [SelectedDropdownID, setSelectedDropdownID] = useState('')
	const [Description, setDescription] = useState('')
	const MonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

	useEffect(() => {
		wsGetCategoryForDropdown()
		if (EditID != 0) {
			setFormEdit(true)
			wsGetCalendarAssetByID()
		}
	}, []);


	const generateMonthArray = (month, year) => {
		const daysInMonth = new Date(year, month, 0).getDate();
		const monthArray = [];

		for (let day = 1; day <= daysInMonth; day++) {
			const dateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T00:00:00`;;
			const dateObj = new Date(year, month - 1, day);
			const dateNameString = `${day.toString().padStart(2, '0')} ${monthNames[dateObj.getMonth()]} ${year}`;
			monthArray.push({ ID: dateString, Name: dateNameString });
		}
		return monthArray;
	};

	const wsGetCategoryForDropdown = () => {
		const objParameter = new clsGetCategoryForDropdown();
		APIController.GetCategoryForDropdown(objParameter)
			.then((response) => {
				if (response.error == null) {
					SetDropdownOption("CategoryID", response.data)
					props.handleNotification(true, response.message,)
				} else {
					props.handleNotification(true, response.message, "error")
				}
			})
	}

	const SetDropdownOption = (dropdownName, changedropdownarray) => {
		const updatedFields = FormDataObject.map((item, ind) => {
			if (item.Type == "dropdown" && item.Name == dropdownName) {
				return {
					...item,
					DropdownOption: changedropdownarray
				};
			} else if (item.Type == "dropdown" && item.Name == 'AssetDate') {
				return {
					...item,
					DropdownOption: generateMonthArray(props.CalendarMonth, props.CalendarYear)
				};
			}
			return item;
		})
		setFormDataObject(updatedFields)
	}


	const wsAddEditCalendarAsset = (FormData) => {
		console.log('FormData', FormData);

		const objParameter = new clsAddEditCalendarAsset();
		objParameter.ID = EditID;
		objParameter.CalendarID = CalendarID;
		objParameter.CategoryID = FormData.CategoryID;
		objParameter.Name = FormData.Name;
		objParameter.Description = Description;
		objParameter.AssetDate = GetDateWithCommonFormat(FormData.AssetDate);
		objParameter.AssetDateTime = FormData.AssetDateTime;
		objParameter.VeevaCode = FormData.VeevaCode;
		objParameter.AssetTypeID = 1;
		objParameter.SequenceNo = 1;
		objParameter.IsLeadGenPost = FormData.IsLeadGenPost ? FormData.IsLeadGenPost : 0;

		APIController.AddEditCalendarAsset(objParameter)
			.then((response) => {
				if (response.status == 1) {
					EditDropDownOption(FormDataObject, '', response.data, 1)
					props.handleNotification(true, response.message)
				} else {
					props.handleNotification(true, response.message, 'error')
				}
				// props.onClose()
				props.handlebind()
			})
	}

	const wsGetCalendarAssetByID = () => {
		const objParameter = new clsGetCalendarAssetByID();
		objParameter.ID = EditID;

		APIController.GetCalendarAssetByID(objParameter)
			.then((response) => {
				if (response.data.length) {
					setCalendarID(response.data[0].CalendarID)
					setDescription(response.data[0].Description)
					EditDropDownOption(FormDataObject, response.data, EditID, 0)
					setFormEditData(response.data[0])
				} else {
					props.handleNotification(true, response.message, 'error')
				}
			})
	}



	// DropdownOption set in formfield 
	const EditDropDownOption = (oldFormData, newFormData, ID, IsSavedClick) => {
		const updatedFormElements = oldFormData.map(element => {
			if (element.Name == "CategoryID" && newFormData != '') {
				return { ...element, DropdownOption: JSON.parse(newFormData[0].CategoryName) };
			} else if (element.Name == "AssetDate" && newFormData != '') {
				return { ...element, DropdownOption: generateMonthArray(props.CalendarMonth, props.CalendarYear) };
			} else if (element.Type == "uploadAssets") {
				return {
					...element,
					ObjectID: ID,
					IsEdit: IsSavedClick != 0 ? false : true,
					IsSavedClick: IsSavedClick
				};
			}
			return element;
		});
		setFormDataObject(updatedFormElements);
		if (newFormData.length) {
			const formattedData = newFormData.map(item => ({
				...item,
				AssetDate: formatDate(item.AssetDate),
				AssetDateTime: formatTime(item.AssetDateTime)
			}));
			setFormEditData(formattedData[0])
		}
	}

	const formatDate = (date) => {
		const formattedDate = new Date(date);
		const month = formattedDate.getMonth() + 1;
		const day = formattedDate.getDate();
		const year = formattedDate.getFullYear();
		return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
	};

	const formatTime = (time) => {
		const formattedTime = new Date(time);
		const hours = formattedTime.getHours();
		const minutes = formattedTime.getMinutes();
		return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
	};

	return (
		<div>
			{(EditID == 0 || FormEditData != '') &&
				<FormControl FormField={FormDataObject} onClose={props.onClose} handleOnclick={wsAddEditCalendarAsset.bind(this)}
					SelectedDropdownID={SelectedDropdownID}
					setSelectedDropdownID={setSelectedDropdownID}
					formEdit={formEdit}
					Description={Description}
					setDescription={setDescription}
					FormEditData={FormEditData} />}
		</div>
	);
}
