import React, { useEffect, useState } from 'react';
import { UploadAssets } from "../../components";
import { IsEmpty, NumberDigitValidation, NumberValidation } from '../../config/appConstant';
import AnynumberInput from '../AnyNumberInput/AnynumberInput';
import Button from '../Button/Button';
import DatePiker from '../DatePikerFormCantorl/DatePikerFormCantorl';
import DateTimePiker from '../DateTimePiker/DateTimePiker';
import Dropdown from '../Dropdown/Dropdown';
import Editor from '../HtmlEditer/editor';
import MultipalCheckbox from '../MultipalCheckbox/MultipalCheckbox';
import NumberTextField from '../NumberTextField/NumberTextField';
import RadioButton from '../RadioButton/RadioButton';
import SearchInput from '../SearchInput/SearchInput';
import SingaleCheckBox from '../SingaleCheckBox/SingaleCheckBox';
import CommonSwitch from '../Switch/CommonSwitch';
import TextField from '../TextField/TextField';
import TimePiker from '../TimePiker/TimePiker';
import HtmlFileUpload from "../HtmlFileUpload/HtmlFileUpload";
import MarketingTitleAddEdit from "../MarketingTitleAddEdit/MarketingTitleAddEdit";
import './formcantrol.css';

export default function FormCantrol(props) {
    const { FormField, handleOnclick, formEdit, IsMediaLibrary, setHtmlFile, setcheckInput, checkInput, FormEditData, ApproveOnclick, SelectedDropdownID, setSelectedDropdownID, setsearchValue, setcheckboxOption, onClose, Description, setDescription, setsearchId, handleSwitch, setBaseHTMLData, setHTMLData, savedFilteronclick, CancelCampaign, isImageDeleted } = props
    const [formvalue, setFormValue] = useState(formEdit ? FormEditData : {});
    console.log('formvalue', formvalue);



    const [formName, setFormName] = useState([]);
    const [Error, setError] = useState({});
    const [PhoneNumbervalue, setPhoneNumberValue] = useState('');
    const [searchDefultValue, setSearchDefultValue] = useState('')
    const [DropdownDefultValue, setDropdownDefultValue] = useState({})
    const [HtmlFileName, setHtmlFileName] = useState('')
    const [singleCheckboxDisable, setsingleCheckboxDisable] = useState(false)


    // value onChange hadle

    const handleonchange = (e, type, numberdigit, dropdownoption, dropdownID, IsDollarSign) => {
        const { name, value } = e.target;
        console.log('value', value);

        if (type == "MobileNumber" && !numberdigit) {
            if (NumberValidation(e) == "() -") {
                setFormValue({ ...formvalue, [type]: "" });
                setError({ ...Error, [name]: true });
            } else if (NumberValidation(e).length !== 14) {
                setFormValue({ ...formvalue, [type]: NumberValidation(e) });
                setError({ ...Error, [name]: true });
            } else {
                setFormValue({ ...formvalue, [type]: NumberValidation(e) });
                setError({ ...Error, [name]: false });
                setPhoneNumberValue(NumberValidation(e));
            };
        } else if (numberdigit) {

            setFormValue({ ...formvalue, [type]: `${IsDollarSign ? "$" : ''}${NumberDigitValidation(e, numberdigit)}` });
            setError({ ...Error, [name]: false });

        } else if (type == 'switch') {
            setFormValue({ ...formvalue, [name]: e.target.checked ? 1 : 0, });
            setError({ ...Error, [name]: false });
            setSelectedDropdownID({ ...SelectedDropdownID, [name]: e.target.checked ? 1 : 0, });
            name == 'IsNew' && handleSwitch(FormField, e.target.checked)

        } else if (type == 'dropdown') {
            const dropdownValue = dropdownoption.filter((item) => {
                if (item.value == value) {
                    return item
                }
            });
            setSelectedDropdownID({ ...SelectedDropdownID, [name]: value });
            setFormValue({ ...formvalue, [name]: value, });
            setError({ ...Error, [name]: false });

        }
        else if (type == 'singalcheckbox') {
            setFormValue({ ...formvalue, [name]: e.target.checked ? 1 : 0, });
            setError({ ...Error, [name]: false });
        }
        else {
            setFormValue({ ...formvalue, [name]: value, });
            setError({ ...Error, [name]: false });
        };
    };

    // Search Suggestions value get
    const handleSearchSuggestion = (event, value, fetchSuggestions, fieldName, id = null) => {

        // Update the form value with the Name and ID
        setsearchId(id)
        setFormValue(prevState => ({
            ...prevState,
            [fieldName]: { Name: value, ID: id }
        }));

        // Fetch suggestions if needed
        if (fetchSuggestions) {
            fetchSuggestions(value);
        }

        // Optionally handle other logic like validation
        if (value === '') {
            setError(prevState => ({
                ...prevState,
                [fieldName]: true
            }));
        } else {
            setError(prevState => ({
                ...prevState,
                [fieldName]: false
            }));
        }
    };



    // Form Button Handle submit
    const handleFormSubmit = (e, Buttontype) => {
        if (Buttontype == 2) {
            const Errorobject = ErrorLogic();
            if (Errorobject == undefined) {
                handleOnclick(formvalue, Buttontype);
            }
        } else if (Buttontype == 3) {
            const Errorobject = ErrorLogic();
            if (Errorobject == undefined) {
                ApproveOnclick(formvalue, Buttontype)
            }
        } else if (Buttontype == 6) {
            savedFilteronclick(formvalue)

        } else if (Buttontype == 4) {
            CancelCampaign(formvalue)
        }
        else {
            props.onClose();
        };
    };

    // setfieldname to state
    const fieldNameSet = (FormEditData) => {
        const DefultValueObj = {};
        const Errorobj = {};
        const formFieldName = FormField.map((item, ind) => {

            if (item.Type == "radio") {
                const defaultChaked = item.RadioOption.filter((val) => {
                    if (val.isDefultChecked) {
                        return val.value;
                    };
                });
                DefultValueObj[item.Name] = defaultChaked[0].value;

                return item.Name;
            } else if (item.Type == "search") {
                setSearchDefultValue(item.DefultValue);
                DefultValueObj[item.Name] = { Name: item.DefaultValue, ID: null };
                Errorobj[item.Name] = false;
                return item.Name;

            } else if (item.Type == "switch") {
                DefultValueObj[item.Name] = !item.DefaultValues ? 0 : item.DefaultValues;
                setFormValue({ ...formvalue, [item.Name]: !item.DefaultValues ? 0 : item.DefaultValues })
            }
            else if (item.Type == "singalcheckbox") {
                DefultValueObj[item.Name] = item.DefaultValues;
                setFormValue({ ...formvalue, [item.Name]: !item.DefaultValues ? 0 : item.DefaultValues })
            }
            else if (item.Type == "time") {

                DefultValueObj[item.Name] = item.DefaultValue;
                setFormValue({ ...formvalue, [item.Name]: item.DefaultValue })
            }
            else if (item.Type == "date") {
                DefultValueObj[item.Name] = item.DefaultValue;
                setFormValue({ ...formvalue, [item.Name]: item.DefaultValue })
                return item.IsMandatory == 0 ? null : item.Name;
            }
            else if (item.Type == "dropdown") {

                DefultValueObj[item.Name] = item.DefaultValue;
                setFormValue({ ...formvalue, [item.Name]: item.DefaultValue })
            }
            else if (item.Type == "HtmlUploadAsset") {
                DefultValueObj[item.Name] = item.DefaultValue;
                setFormValue({ ...formvalue, [item.Name]: item.DefaultValue })
                return item.IsMandatory == '1' && item.Name
            }
            else if (item.Type == "input") {
                // DefultValueObj[item.Name] = item.DefaultValue;
                setFormValue({ ...formvalue, [item.Name]: '' })
                return item.IsMandatory == '1' && item.Name
            }
            else if (item.Type == "datetime-local") {
                // DefultValueObj[item.Name] = item.DefaultValue;
                setFormValue({ ...formvalue, [item.Name]: '' })
                return item.IsMandatory == '1' && item.Name
            }
            else if (item.Type == "number") {
                DefultValueObj[item.Name] = item.IsDollarSign ? '$' : '';
                // setFormValue({ ...formvalue, [item.Name]: item.IsDollarSign ? '45' : 'fgdf' })
                return item.IsMandatory == '1' && item.Name
            }
            else if (item.Type == "checkbox") {
                // DefultValueObj[item.Name] = item.DefaultValue;
                setFormValue({ ...formvalue, [item.Name]: '' })
                return item.IsMandatory == '1' && item.Name
            }
            // } else if (item.Type == "dropdown") {
            //     const defultvalueArry = []
            //     const defaultChaked = item.DropdownOption.map((val) => {

            //         if (val.IsSelect) {
            //             defultvalueArry.push({ [item.Name]: val.value })
            //             return val.value;
            //         };
            //     });
            //     if (defaultChaked != []) {
            //         // setDropdownDefultValue({...DropdownDefultValue,[item.Name]:defaultChaked[0].value});
            //         // // setFormValue({...formvalue,[item.Name]:defaultChaked[0].value})
            //         // DefultValueObj[item.Name] = defaultChaked[0].value;
            //         // Errorobj[item.Name] = false;
            //     } else {
            //         DefultValueObj[item.Name] = '';
            //         Errorobj[item.Name] = true;
            //     }
            else {
                if (item.DefaultValues) {
                    DefultValueObj[item.Name] = item.DefaultValues;
                    Errorobj[item.Name] = false;
                    return item.Name;
                } else {
                    DefultValueObj[item.Name] = "";
                    Errorobj[item.Name] = false;
                    return item.Name;
                }
            };
        });

        const RemoveLastItem = formFieldName.slice(0, -1);
        const DefultValueName = RemoveLastEle(DefultValueObj);
        const ErrorObj = RemoveLastEle(Errorobj);

        if (formEdit == true) {
            setFormValue(FormEditData);

        } else {
            setFormValue(DefultValueName);
        }
        setFormName(RemoveLastItem);
        setError(ErrorObj);
    };

    // error logic add
    const ErrorLogic = () => {
        const errorchecks = formName.filter((item) => {
            if (formvalue[item] == '' || formvalue[item] == undefined || formvalue[item].length == 0) {
                return item
            };
        });

        const removeUndefind = errorchecks.filter((item) => {
            if (item != undefined && item != 'Upload' && item != 'ProductFilters' && item != "TagLine" && item != 'Unit' && item != 'AssetTypeID' && item != 'Prefix' && item != 'GlobalModuleTabs' && item != 'RoleIDs' && item != 'TutorialLink' && item != 'ExternalURL') {
                return item;
            };
        });

        if (removeUndefind.length != 0) {
            const errortrue = removeUndefind.map((item, ind) => {
                const Errorobj = {};
                Errorobj[item] = true ? true : false;
                return Errorobj;
            });

            const mergedObject = errortrue.reduce((acc, obj) => {
                return { ...acc, ...obj };
            }, {});
            for (let key in Error) {
                if (mergedObject?.hasOwnProperty(key) && Error[key] !== mergedObject[key]) {
                    setError(prevState => ({
                        ...prevState,
                        [key]: mergedObject[key]
                    }));
                };
            };
            return mergedObject;
        };
    };

    const RemoveLastEle = (Obj) => {
        const entries = Object.entries(Obj);
        entries.pop();
        return Object.fromEntries(entries);
    };


    useEffect(() => {
        fieldNameSet(FormEditData);
    }, []);


    return (

        <div className='form-main'>
            <div className='form-field-main'>
                {FormField.map((item, index) => {

                    if (item.Type == "input") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <TextField error={item.IsMandatory == 1 ? Error[item.Name] : false} IsDisabled={item.IsDisabled} multiline={item.multiline} rows={item.rows} label={item.Label} type={item.Type} IsMandatory={item.IsMandatory} name={item.Name} value={formvalue[item.Name]} onChange={(e) => handleonchange(e)} />
                            </div>
                        )
                    } else if (item.Type == "dropdown") {

                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <Dropdown error={item.IsMandatory == 1 ? Error[item.Name] : false} id={item.ID} DropdownDefultValue={DropdownDefultValue[item.Name]} label={item.Label} name={item.Name} DropdownOption={item.DropdownOption} value={formvalue[item.Name] == undefined ? "" : formvalue[item.Name]} onChange={(e) => handleonchange(e, item.Type, '', item.DropdownOption, item.ID)} />

                            </div>
                        )
                    } else if (item.Type == "radio") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <RadioButton RadioOption={item.RadioOption} lable={item.Label} value={formvalue[item.Name]} name={item.Name} formvalue={formvalue} setFormValue={setFormValue} />
                            </div>
                        )
                    } else if (item.Type == "MobileNumber") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <NumberTextField error={item.IsMandatory == 1 ? Error[item.Name] : false} formvalue={formvalue} setFormValue={setFormValue} label={item.Label} name={item.Name} value={formvalue[item.Name]} PhoneNumbervalue={PhoneNumbervalue} onChange={(e) => handleonchange(e, item.Name)} />
                            </div>
                        )
                    } else if (item.Type == "pincode") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <AnynumberInput error={item.IsMandatory == 1 ? Error[item.Name] : false} label={item.Label} name={item.Name} maxLength={item.maxLength} value={formvalue[item.Name]} onChange={(e) => handleonchange(e, item.Name, item.maxLength)} />
                            </div>
                        )
                    } else if (item.Type == "number") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <AnynumberInput error={item.IsMandatory == 1 ? Error[item.Name] : false} label={item.Label} name={item.Name} IsDollarSign={item.IsDollarSign} maxLength={item.MaxLength ? item.MaxLength : 1000000} value={`${formvalue[item.Name]}`} onChange={(e) => handleonchange(e, item.Name, 100000000, '', '', item.IsDollarSign)} />
                            </div>
                        )
                    } else if (item.Type == "price") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                {/* <AnynumberInput error={item.IsMandatory ? Error[item.Name] : false} label={item.Label} name={item.Name} maxLength={1000000} value={formvalue[item.Name]} onChange={(e) => handleonchange(e, item.Name, 100000000)} /> */}
                            </div>
                        )
                    } else if (item.Type == "date") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <DatePiker error={item.IsMandatory == 1 ? Error[item.Name] : false} label={item.Label} name={item.Name} value={formvalue[item.Name]} CalendarYear={item.CalendarYear} CalendarMonth={item.CalendarMonth} IsSingleMonth={item.IsSingleMonth} formvalue={formvalue} setFormValue={setFormValue} onChange={(e) => handleonchange(e)} />
                            </div>
                        )
                    } else if (item.Type == "time") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <TimePiker error={item.IsMandatory == 1 ? Error[item.Name] : false} label={item.Label} type={item.Type} name={item.Name} value={formvalue[item.Name]} onChange={(e) => handleonchange(e)} />
                            </div>
                        )
                    } else if (item.Type == "datetime-local") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <DateTimePiker disabled={!IsEmpty(formvalue['IsNotifyMe']) ? formvalue['IsNotifyMe'] == 1 ? false : true : item.disable ? true : false} error={item.IsMandatory == 1 ? Error[item.Name] : false} label={item.Label} name={item.Name} value={formvalue[item.Name]} onChange={(e) => handleonchange(e)} />
                            </div>
                        )
                    } else if (item.Type == "switch") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <CommonSwitch label={item.Label} name={item.Name} value={formvalue[item.Name] == 1 ? true : false} checked={formvalue[item.Name] == 1 ? true : false} onChange={(e) => handleonchange(e, item.Type, '', '',)} />
                            </div>
                        )
                    } else if (item.Type == "search") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <SearchInput error={item.IsMandatory == 1 ? Error[item.Name] : false} editvalue={formEdit ? formvalue[item?.Name] : ''} searchDefultValue={searchDefultValue} label={item.Label} name={item.Name} SearchSuggestion={item.SearchSuggestion} value={formvalue[item.Name]?.Name || ''}
                                    onInputChange={(event, value, fetchSuggestions, id) =>
                                        handleSearchSuggestion(event, value, fetchSuggestions, item.Name, id)
                                    } />
                                {/* onInputChange={(event, value, fetchSuggestions) => 
                                    handleSearchSuggestion(event, value, fetchSuggestions, item.Name, item.SearchSuggestion)} /> */}
                            </div>
                        )
                    } else if (item.Type == "checkbox") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <MultipalCheckbox error={item.IsMandatory == 1 ? Error[item.Name] : false} setcheckboxOption={setcheckboxOption} formEdit={formEdit} name={item.Name} lable={item.Label} value={item.checkboxOption} formvalue={formvalue} setFormValue={setFormValue} />
                            </div>
                        )
                    } else if (item.Type == "singalcheckbox") {
                        return (
                            <div key={index} className="form-field-fullwidth">
                                <SingaleCheckBox type='checkbox' setcheckInput={setcheckInput} checkInput={checkInput} IsInput={item.IsInput} error={item.IsMandatory == 1 ? Error[item.Name] : false} id={item.Name} label={item.Label} label2={item.Label2} name={item.Name} checked={formvalue[item.Name] == 1 ? true : false} onChange={(e) => handleonchange(e, item.Type, '', '',)} />
                            </div>
                        );
                    } else if (item.Type == "uploadAssets") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <UploadAssets isImageDeleted={isImageDeleted} IsMediaLibrary={IsMediaLibrary} isMultipleAdded={item.isMultipleAdded} ObjectID={item.ObjectID} IsByAdmin={item.IsByAdmin} IsEdit={item.IsEdit} Label={item.Label} IsSavedClick={item.IsSavedClick} formvalue={formvalue} DocumentTypeID={item.DocumentTypeID} onClose={onClose} />
                            </div>
                        )
                    } else if (item.Type == "HtmlEditer") {
                        return (
                            <div key={index} id="htmlediter-main" className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <Editor content={Description} setContent={setDescription} />
                            </div>
                        )
                    } else if (item.Type == "marketing") {
                        return (
                            <div key={index} id="htmlediter-main" className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <MarketingTitleAddEdit />
                            </div>
                        )
                    } else if (item.Type == "HtmlUpload") {
                        return (
                            <div key={index} style={{ display: 'flex', alignItems: 'center' }} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <label htmlFor="fileupload" className="custom-file-upload">
                                    Upload HTML File
                                </label>
                                <input
                                    id="fileupload"
                                    name="files"
                                    type="file"
                                    accept=".htm,.html"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file && file.type === "text/html") {
                                            const reader = new window.FileReader();
                                            reader.onload = (data) => {
                                                setHtmlFile(data.target.result);
                                            };
                                            reader.readAsText(file);
                                            setHtmlFileName(file.name);
                                        }

                                    }}
                                />
                                {HtmlFileName && <p className="file-name">Selected file: {HtmlFileName}</p>}
                            </div>
                        )
                    } else if (item.Type == "HtmlUploadAsset") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <HtmlFileUpload HTMLData={setHTMLData} BaseHTMLData={setBaseHTMLData} />
                            </div>
                        )
                    }
                    else if (item.Type == "button") {
                        return (
                            <div key={index} className='form-button'>
                                {item.Button.map((val, ind) => {
                                    return (
                                        <div key={ind} className='from-button-inner'>
                                            <Button type={val.Buttontype == 1 ? 4 : val.Buttontype} onClick={(e) => handleFormSubmit(e, val.Buttontype)} title={val.lable} />
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                })}
            </div>
        </div>

    )
}

