export function GetBaseURL() {
    var HostName = window.location.hostname;
    var ReturnURL = "";
    if (HostName.indexOf("usosmconnect.com") >= 0) {
        ReturnURL = "https://api.usosmconnect.com/";    // USOSM Connect Live
    } else if (HostName.indexOf("oralsurgeryconnect.com") >= 0) {
        ReturnURL = "https://api.oralsurgeryconnect.com/";    // Oral Surgery Live
    } else if (HostName.indexOf("mosquitonixconnect.com") >= 0) {
        ReturnURL = "https://api.mosquitonixconnect.com/";    //Hostgator - Yomi
    } else if (HostName.indexOf("bl.console.mybauschreach.com") >= 0) {
        ReturnURL = "https://bl.api.mybauschreach.com/";    // my BauschReach Live
    } else if (HostName.indexOf("yomi.portal.thestaging.cc") >= 0) {
        ReturnURL = "https://yomi.api.thestaging.cc/";    //Hostgator - Yomi
    } else {
        //Local
        // ReturnURL = "https://bl.api.mybauschreach.com/";
        // ReturnURL = "https://api.oralsurgeryconnect.com/";
        // ReturnURL = "https://api.mosquitonixconnect.com/";
        ReturnURL = "https://bl.api.thestaging.cc/";
        // ReturnURL = 'http://192.168.1.11:91/'
        // ReturnURL = 'http://192.168.1.14'
        // ReturnURL = 'http://VMOBEE-SERVER:91/'
    }

    return ReturnURL;
}

export const URLs = {
    base: GetBaseURL(),
    googlekey: "AIzaSyAii_9SU6ec6sCpwyaHIurMBvktQ8fv6JI", //google API key
    AddEditDocument: GetBaseURL() + "api/Master/AddEditDocument",
};
