export const Images = {
  downarrow: require('../../assets/icons/down-arrow.png'),
  cross: require('../../assets/icons/cross.png'),
  FooterBG: require('../../assets/images/footer-bg.png'),
  Noimage: require('../../assets/images/not-image.png'),
  NavigateHome: require('../../assets/icons/home-navigation.png'),
  Ic_tutorial: require('../../assets/icons/Ic_tutorial.png'),
  LayerIcon: require('../../assets/icons/Layericon.png'),
  locationPinIcon: require('../../assets/icons/location-pin-icon.png'),
  LeadGenIcon: require('../../assets/icons/LeadGenIcon.png'),
  Downwardarrow: require('../../assets/icons/downwardarrow.png'),
  Viewicon: require('../../assets/icons/view_icon.png'),
  // RightArrowGray: require('../../assets/images/right-arrow-grey.svg').default,
  // LeftArrowGray: require('../../assets/images/left-arrow-grey.svg').default,
  LeftArrowGray: require('../../assets/images/LeftArrowGray.png'),
  RightArrowGray: require('../../assets/images/RightArrowGray.png'),
  Profileimg: require('../../assets/images/profile_generic.png'),
  MacBook: require('../../assets/images/macbook.png'),
  Search: require('../../assets/icons/search.png'),
  // Next: require('../../assets/images/next.png'),
  // Previous: require('../../assets/images/Previousarrows.png'),

  NextGrayArrows: require('../../assets/images/NextGrayArrows.png'),
  BackGrayArrows: require('../../assets/images/BackGrayArrows.png'),
  booking: require('../../assets/images/bookingbusiness.png'),

  tileButtonLeftHover: require("../../assets/images/tile-button-left-hover.svg").default,
  tileButtonLeftRollover: require("../../assets/images/tile-button-left-rollover.svg").default,
  tileButtonRightHover: require("../../assets/images/tile-button-right-hover.svg").default,
  tileButtonRightRollover: require("../../assets/images/tile-button-right-rollover.svg").default,

  round_button_left: require("../../assets/icons/Newicons/round-button-left.svg").default,

  // FooterBG: 'https://cobrand.api.thestaging.cc/Images/DoctorLogos/e80cb76-562-d50c-44db-b5886174241d.jpeg?v=2899591964'

  // New images


  contactusbg: require('../../assets/images/contactusbg1.jpg'),

  pdf: require('../../assets/icons/pdf.png'),
  zip: require('../../assets/icons/zip.png'),
  video: require('../../assets/icons/play.png'),
  csv: require('../../assets/icons/csv.png'),
  html: require('../../assets/icons/files.png'),

  ic_email_contactus: require('../../assets/icons/Newicons/email.png'),
  Play_IC: require('../../assets/icons/Newicons/tutorial-icon.png'),
  googleverifiedicon: require('../../assets/icons/Newicons/verified-location.svg').default,
  googleunverified: require('../../assets/icons/Newicons/unverified-location.svg').default,
  Instagram_IC: require('../../assets/icons/Newicons/instagram.png'),
  Facebook_IC: require('../../assets/icons/Newicons/facebook.png'),
  LinkedIn_IC: require('../../assets/icons/Newicons/LI-In-Bug.png'),
  facebook_teal: require('../../assets/icons/Newicons/facebook-teal.svg').default,
  instagram_teal: require('../../assets/icons/Newicons/instagram-teal.svg').default,
  Postlibrary_IC: require('../../assets/images/postlibraryicon.svg').default,
  AI_Ic: require('../../assets/icons/AI_Icon-removebg-preview.png'),
  Upload_IC: require('../../assets/icons/My_Images_Icon-removebg-preview.png'),
  Section_IC: require('../../assets/images/Sections.png'),
  plushtmlbuilder: require('../../assets/images/plushtmlbuilder.png'),
  Favourite_IC: require('../../assets/icons/stockimagesIC.svg').default,
  dollar_Ic: require('../../assets/icons/dollar.png'),
  vs_vsp_IC: require('../../assets/icons/vs_vsp.png'),
  new_IC: require('../../assets/icons/newicon.png'),
  // view_IC: require('../../assets/icons/Newicons/view.svg').default,
  view_IC: require('../../assets/images/campaignoptions.svg').default,
  download_IC: require('../../assets/icons/download.png'),
  refresh_IC: require('../../assets/icons/circle.svg').default,
  mainLogo: require('../../assets/images/logo.svg'),
  checkmark_IC: require('../../assets/icons/checkmark2.png'),
  fail_IC: require('../../assets/icons/fail2.png'),
  favourite: require('../../assets/icons/favourite.png'),
  redfavourite: require('../../assets/icons/red-favourite.png'),
  Delete_IC: require('../../assets/icons/delete.png'),

  ic_edit: require('../../assets/images/ic_edit.svg').default,
  ic_setting: require('../../assets/images/settings.svg').default,
  ic_contactus: require('../../assets/images/contactus1.svg').default,
  ic_location: require("../../assets/images/mylocations.svg").default,
  ic_landing_page: require("../../assets/images/ic_Landing_Pages.svg").default,
  ic_calculator_grey: require("../../assets/images/ic_calculator_grey.svg").default,
  costtoolprofile: require("../../assets/images/costtoolprofile.svg").default,
  ic_power_btn: require("../../assets/images/power-button-ic.svg").default,
  icon_order: require("../../assets/images/ic_orders.svg").default,
  Ic_orders: require("../../assets/images/ic_orders.svg").default,
  media_library: require("../../assets/images/medialibrary.svg").default,
  Ic_Profile: require("../../assets/images/profile_generic1.png"),
  Ic_blackavatarmsq: require("../../assets/images/blackavatarmsq.png"),
  icon_cart: require("../../assets/images/shopping-cart.png"),
  notification_icon: require("../../assets/images/notification-icon.png"),
  Ic_dashboard: require("../../assets/images/analytics-icon-small-grey.svg").default,
  Ic_subscription: require("../../assets/images/ic_subscription.svg").default,
  Ic_MQXSocialCalendar: require("../../assets/images/MQXSocialCalendar.png"),
  Ic_MQXAnalytics: require("../../assets/images/MQXAnalytics.png"),
  Ic_MQXTheBuzz: require("../../assets/images/MQXTheBuzz.png"),
  Ic_miscellenous: require("../../assets/images/ic_miscellenous.svg").default,
  Ic_notification: require("../../assets/images/notification-svg.svg").default,
  Ic_cart: require("../../assets/images/shopping-cart-icon.svg").default,
  comment: require("../../assets/icons/comment.png"),

  artwork_info: require("../../assets/icons/Newicons/artwork-info.svg").default,
  lead_gen_ad: require("../../assets/icons/Newicons/lead-gen-ad.svg").default,
  multiple_posts: require("../../assets/icons/Newicons/multiple-posts.svg").default,
  new_post: require("../../assets/icons/Newicons/new-post.svg").default,

  green_check_ic: require("../../assets/images/green-check-ic.svg").default,
  thank_you_page_header_logo: require("../../assets/images/thank_you_page_header_logo.png"),
  Simply_Notedleft: require("../../assets/images/SimplyNotedLeft.png"),
  writeicon: require("../../assets/images/writeicon.svg").default,
  downarrowblue: require("../../assets/images/downarrow-blue.png"),
  Vs: require("../../assets/icons/vs.png"),
  Vsp: require("../../assets/icons/VSP.png"),

}
