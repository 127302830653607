import PropTypes from "prop-types";
import React from 'react';
import { Text } from "../../components";
import { Images } from "../../config";
import styles from './PageTitleStyle';
import { useNavigate } from "react-router-dom";

const PageTitle = (props) => {
  let history = useNavigate();

  const { title, subTitle, isborderShow } = props
  const isMacOS = /Mac/i.test(navigator.platform);

  return (
    <div style={isborderShow ? styles.borderbotttom : (isMacOS ? styles.macPageMain : styles.pageMain)} >
      <img onClick={() => history('/home')} style={styles.HomeIcon} src={Images.NavigateHome} alt="edit" />
      <Text title2 semibold >{title}</Text> &nbsp;
      <Text title2 semibold grayColor >{subTitle}</Text>
    </div >
  );
}
PageTitle.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  subTitle: PropTypes.string,
  title: PropTypes.string,
  isborderShow: PropTypes.bool,
  onClick: PropTypes.func,
};

PageTitle.defaultProps = {
  style: {},
  subTitle: '',
  title: '',
  isborderShow: true,
  onClick: () => { },

}

export default PageTitle;
